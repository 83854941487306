import React from "react"
import styled from "@emotion/styled"
import RandomiserImage from "./RandomiserImage"
import { v4 as uuid } from "uuid"

const Container = styled.div`
  height: 100%;
  position: relative;
  overflow: hidden;
`

export default function Randomiser({ images }) {
  return (
    <Container>
      {Object.entries(images).map(function (row, i) {
        return (
          <RandomiserImage
            image={row[1].image}
            key={uuid()}
            i={i}
            zIndex={row[1].zIndex}
          />
        )
      })}
    </Container>
  )
}
