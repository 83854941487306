import React, { useEffect } from "react"
import Layout from "../../components/global/layout"
import Randomiser from "../../components/builder/Randomiser"
import { vulvaBuilderCatalog as catalog } from "../../data/vulva-maker"
import styled from "@emotion/styled"
import { useDispatch, useSelector } from "react-redux"
import ButtonLink from "../../components/form/ButtonLink"
import {
  generateImageMap,
  generateRandomVulva,
} from "../../utils/vulvaBuilderUtils"
import { urls } from "../../data/urls"
import { VulvaPreloader } from "../../components/builder/Preloader"

const PageLayout = styled.div``

const ImageContainer = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
`
const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  z-index: 10;
  display: grid;
  padding: ${props => (props.fullscreen ? "0  16px 32px 16px" : "32px 16px")};
  gap: 16px;
  width: 100%;
  justify-content: center;
  align-items: center;
`

export default function VulvaBuilderPage() {
  const dispatch = useDispatch()
  const state = useSelector(state => state.app.vulva)

  useEffect(() => {
    const interval = setInterval(() => {
      generateRandomVulva(dispatch, catalog)
    }, 2000)
    return () => clearInterval(interval)
  }, [dispatch])

  const imageMap = generateImageMap(state, catalog)
  return (
    <Layout title={"Make your own vulva"} marginless>
      <VulvaPreloader />
      <PageLayout>
        <ImageContainer>
          <Randomiser images={imageMap} cover />
        </ImageContainer>
        <ButtonContainer>
          <ButtonLink
            backgroundColor={props => props.theme.rose}
            buttonStyle={"inverted"}
            to={urls.VM.MAKER}
          >
            Let's get started
          </ButtonLink>
        </ButtonContainer>
      </PageLayout>
    </Layout>
  )
}
