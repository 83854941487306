import React from "react"
import styled from "@emotion/styled"

const Container = styled.figure`
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: ${props => (props.zindex ? props.zindex : "unset")};
`
const Image = styled.img`
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  will-change: transform, opacity;
  animation: ${props => (props.static ? `fadeIn` : `bounceIn`)} 1s;

  @keyframes fadeIn {
    from {
      opacity: 0%;
    }
    to {
      opacity: 100%;
    }
  }

  @keyframes bounceIn {
    from,
    20%,
    40%,
    60%,
    80%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
      transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
      transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
      opacity: 1;
      transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
      transform: scale3d(0.97, 0.97, 0.97);
    }

    to {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }
  }
`

export default function RandomiserImage({ image, zIndex, i }) {
  return (
    <Container zindex={zIndex}>
      <Image src={`/images/vulva-maker${image}`} alt="" static={zIndex === 0} />
    </Container>
  )
}
